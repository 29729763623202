import { GroupCondition } from '@/api/enums';
import { equal, group } from '@/api/helpers';
import { FiltersType } from '@/api/types';
import { MaterialType } from '@/client/courses';
import {
  CourseSchedulePeriodUnit,
  PlannerCRUDCourse,
} from '@/client/planner/types';
import { Subjects } from '@/client/users';
import { useCoursesPartialRequest } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { selectCurrentAccount } from '@/store/features/account';
import { DateFormats } from '@/system-settings/enums';
import { AppButton } from '@/ui/buttons';
import { AppCalendar } from '@/ui/calendar';
import { FlexContainer } from '@/ui/styled-ui';
import { TimelineIconPath } from '@/utils/helpers';
import { updateTimeline } from '@/utils/planner';
import { t } from 'i18next';
import moment from 'moment';
import { ScrollPanel } from 'primereact/scrollpanel';
import { FormEvent } from 'primereact/ts-helpers';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PlannerCourseAddButton } from './PlannerCourseAddButton';
import { PlannerImageCard } from './PlannerImageCard';
import { PlannerPeriodSelector } from './PlannerPeriodSelector';

const StyledScrollPanel = styled(ScrollPanel)`
  width: 100%;
  height: 290px;
`;

const StyledDiv = styled.div`
  margin: 0 var(--xsmall-padding);
  position: relative;
  width: 160px;

  &.date-header {
    position: relative;
    margin-left: 36px;

    svg {
      display: inline-block;
      position: absolute;
      left: -26px;
      height: 100%;
      vertical-align: middle;
      width: 8px;

      path {
        fill: var(--gray-dark);
      }

      &.red {
        path {
          fill: var(--red-main);
        }
      }
    }
  }
`;

const StyledDateSpan = styled.span`
  font-size: var(--xsmall-font-size);
  text-align: center;
  color: var(--gray-darker);
  margin: auto;
  z-index: 99;
  position: absolute;
  bottom: -32px;
  left: 0;

  &.red {
    color: var(--red-main);
  }
`;

const StyledAppCalendar = styled(AppCalendar)`
  border-radius: var(--xsmall-border-radius);
  border: 1px solid var(--gray-dark);
  > input {
    height: auto;
    border-radius: var(--xsmall-border-radius);
    padding: var(--small-padding);
  }

  &:hover {
    border-color: var(--red-main);
  }
`;

const StyledPlaceholderDiv = styled.div`
  height: 170px;
  border: 2px dashed var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
`;

const StyledDatePlaceholderDiv = styled.div`
  height: 50px;
  border: 2px dashed var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
`;

type PlannerCRUDTimelineProps = {
  items: PlannerCRUDCourse[];
  isOnboardingPlan?: boolean;
  onItemAdd: (item: PlannerCRUDCourse) => void;
  onItemDateChange: (
    date: Date,
    item: PlannerCRUDCourse,
    releaseDate?: Date | null,
  ) => void;
  onItemPeriodChange: (
    periodValue: number,
    periodType: CourseSchedulePeriodUnit,
    itemId: string,
  ) => void;
  onItemRemove: (item: PlannerCRUDCourse) => void;
  additionalFilters?: FiltersType;
};

export const PlannerCRUDTimeline: React.FC<PlannerCRUDTimelineProps> = ({
  items,
  isOnboardingPlan,
  onItemAdd,
  onItemDateChange,
  onItemPeriodChange,
  onItemRemove,
  additionalFilters,
}) => {
  const account = useAppSelector(selectCurrentAccount);
  const scrollPanelRef = useRef<any>(null);
  const { canUseFeature } = useFeatureFlag();

  const [timelineItems, setTimelineItems] = useState<PlannerCRUDCourse[]>([]);
  const [arePastCoursesVisible, setArePastCoursesVisible] = useState(false);

  const { courses, isLoading: isCoursesLoading } = useCoursesPartialRequest({
    filters: [
      equal('availableToAccounts', account?.id),
      equal('retired', false),
      group(GroupCondition.OR, [
        equal('type', MaterialType.COURSE),
        ...(canUseFeature(Subjects.MATERIALS_UPLOAD)
          ? [equal('type', MaterialType.CUSTOM_MATERIAL)]
          : []),
      ]),
      ...(additionalFilters ? additionalFilters : []),
    ],
    sort: [['priority', 'asc'].join(',')],
  });

  useEffect(() => {
    updateTimeline(items, setTimelineItems, isOnboardingPlan);

    const slider = scrollPanelRef?.current?.getElement();
    const draggableElement = slider?.querySelector('.p-scrollpanel-content');
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      draggableElement.scrollBy({ left: event.deltaY < 0 ? -30 : 30 });
    };

    if (draggableElement) {
      draggableElement.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (draggableElement) {
        draggableElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [items]);

  const isAllTimelineItemsPastDate = () => {
    return timelineItems.every((item) => item.isPastDate);
  };

  const Placeholder = () => (
    <div className="flex relative">
      <div>
        <StyledDateSpan className="red">{t('now')}</StyledDateSpan>
        <StyledDiv className="date-header relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="204"
            viewBox="0 0 7 204"
            fill="none"
            className="red"
          >
            <TimelineIconPath />
          </svg>
          <StyledPlaceholderDiv />
          <StyledDatePlaceholderDiv className="mt-2" />
        </StyledDiv>
      </div>
    </div>
  );

  return (
    <div className="w-full">
      {items?.length ? (
        <StyledScrollPanel ref={scrollPanelRef}>
          <div className="flex pb-5">
            {!arePastCoursesVisible &&
              timelineItems.some(({ isPastDate }) => isPastDate) && (
                <div className="flex align-items-center mr-3">
                  <AppButton
                    size="xs"
                    icon="pi pi-history"
                    onClick={() => setArePastCoursesVisible(true)}
                    tooltip={t('planner.see.past.courses')}
                  />
                </div>
              )}

            {timelineItems.map((item, index) => (
              <div
                key={`${index}${item.enrollDate}${item.id}`}
                className="flex relative"
              >
                {(!item.isPastDate ||
                  (item.isPastDate && arePastCoursesVisible)) && (
                  <>
                    {item.isDateHeader && (
                      <StyledDateSpan
                        className={
                          item?.firstNonPastDate && !isOnboardingPlan
                            ? 'red'
                            : ''
                        }
                      >
                        {item.firstNonPastDate && !isOnboardingPlan
                          ? t('now')
                          : item.dateKey}
                      </StyledDateSpan>
                    )}
                    <StyledDiv
                      className={
                        item.isDateHeader ? 'date-header relative' : 'relative'
                      }
                    >
                      {item.isDateHeader && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="204"
                          viewBox="0 0 7 204"
                          fill="none"
                          className={
                            item?.firstNonPastDate && !isOnboardingPlan
                              ? 'red'
                              : ''
                          }
                        >
                          <TimelineIconPath />
                        </svg>
                      )}
                      <PlannerImageCard
                        item={item}
                        isOnboardingPlan={isOnboardingPlan}
                        onItemRemove={onItemRemove}
                        isCRUD
                      />
                      {!isOnboardingPlan && (
                        <StyledAppCalendar
                          id="enroll-date"
                          name="enrollDate"
                          className="w-full white-input mt-2"
                          placeholder={t('generic.select')}
                          value={
                            item?.enrollDate
                              ? moment(item.enrollDate).toDate()
                              : undefined
                          }
                          formatDateTime={(date) =>
                            moment(date).format(DateFormats.TIMEDATE_SHORT)
                          }
                          minDate={
                            moment(item.releaseDate).isAfter(moment(), 'days')
                              ? moment(item.releaseDate).toDate()
                              : new Date()
                          }
                          onChange={(event: FormEvent) => {
                            onItemDateChange(
                              moment(event.value?.toString())
                                .utc(true)
                                .toDate(),
                              item,
                            );
                          }}
                          disabledDates={timelineItems
                            .filter((i) => i.id === item.id)
                            .map((i) => new Date(i.enrollDate!))}
                          readOnlyInput
                          icon="pi pi-calendar"
                          iconPos="left"
                          style={{ width: '160px !important' }}
                          disabled={
                            item.isPastDate ||
                            !!item.executedAt ||
                            item.disabled
                          }
                        />
                      )}
                      {isOnboardingPlan && (
                        <PlannerPeriodSelector
                          periodValue={item.periodValue}
                          periodUnit={item.periodUnit}
                          onChange={(
                            periodValue: number,
                            periodUnit: CourseSchedulePeriodUnit,
                          ) =>
                            onItemPeriodChange(periodValue, periodUnit, item.id)
                          }
                        />
                      )}
                    </StyledDiv>
                  </>
                )}

                {item.isLastOfDateGroup &&
                  (!item.isPastDate ||
                    isOnboardingPlan ||
                    (index === timelineItems?.length - 1 &&
                      isAllTimelineItemsPastDate())) && (
                    <>
                      {isAllTimelineItemsPastDate() && Placeholder()}
                      <PlannerCourseAddButton
                        courses={courses}
                        timelineItems={timelineItems}
                        currentTimelineItem={
                          isAllTimelineItemsPastDate() ? undefined : item
                        }
                        onItemAdd={onItemAdd}
                        isCoursesLoading={isCoursesLoading}
                        isOnboarding={isOnboardingPlan}
                      />
                    </>
                  )}
              </div>
            ))}
          </div>
        </StyledScrollPanel>
      ) : (
        <FlexContainer direction="column" gap={0}>
          <PlannerCourseAddButton
            courses={courses}
            timelineItems={timelineItems}
            onItemAdd={onItemAdd}
            isCoursesLoading={isCoursesLoading}
            isOnboarding={isOnboardingPlan}
          />
        </FlexContainer>
      )}
    </div>
  );
};

import { equal } from '@/api/helpers';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import { AccountFeatureFlag, FeatureFlag } from '@/client/feature-flags/types';
import { SystemRoles } from '@/client/users';
import { LoadingStatuses } from '@/common/constants';
import { allRolesOptions } from '@/common/constants/roles';
import { AccountsMultiselectInput } from '@/components/form/selectors';
import { useRoles } from '@/hooks/query';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import {
  selectCurrentAccount,
  setCurrentAccount,
} from '@/store/features/account';
import { setCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Dialog, DialogProps } from 'primereact/dialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type FeatureFlagsModalProps = {
  currentFeature?: AccountFeatureFlag | FeatureFlag | null;
  isSubmitting?: boolean;
  isAccount?: boolean;
  onSubmit: (roles: string[], accounts?: string[]) => void;
} & DialogProps;

export const FeatureFlagsModal: React.FC<FeatureFlagsModalProps> = ({
  currentFeature,
  isSubmitting,
  isAccount,
  onSubmit,
  onHide,
  visible,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const [featureRoles, setFeatureRoles] = useState<SystemRoles[]>([]);
  const [featureAccounts, setFeatureAccounts] = useState<Account[]>([]);

  const { roles } = useRoles({
    take: 20,
  });

  useEffect(() => {
    if (isAccount) {
      setFeatureRoles(
        (currentFeature as AccountFeatureFlag)?.roles?.map(
          ({ code }) => code,
        ) || [],
      );
    }
  }, [currentFeature]);

  const handleEditFeatureFlag = async () => {
    const rolesIds = featureRoles
      .map((role) => roles?.result.find(({ code }) => code === role)?.id || '')
      .filter(Boolean);
    const accountsIds = featureAccounts.map(({ id }) => id);

    await onSubmit(rolesIds, accountsIds);

    // Refetch permissions
    const response = await client.accounts.getAccount(currentAccount?.id);
    dispatch(setCurrentAccount(response));
    const user = await client.default.getMe();
    dispatch(setCurrentUser(user));
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={
        <h1>
          {t(
            `features.${
              isAccount
                ? (currentFeature as AccountFeatureFlag)?.featureFlag?.key
                : (currentFeature as FeatureFlag)?.key
            }`,
          )}
        </h1>
      }
      onHide={onHide}
      draggable={false}
      data-testid="features-modal"
    >
      <FlexContainer
        gap={24}
        justify="flex-start"
        direction="column"
        className="mb-4"
      >
        <div className="flex flex-column field w-full">
          <label>{t('generic.role')}</label>
          <MultiSelect
            value={featureRoles}
            dataKey="id"
            placeholder={t('roles.select')}
            optionLabel="label"
            display="chip"
            options={allRolesOptions}
            className="w-full"
            onChange={(e: DropdownChangeEvent) => {
              setFeatureRoles(e.value);
            }}
          />
        </div>
        {!isAccount && (
          <div className="flex flex-column field w-full">
            <label>{t('accounts')}</label>
            <AccountsMultiselectInput
              className="w-full"
              selectedOptions={featureAccounts}
              additionalFilters={[equal('active', true)]}
              onChange={(e) => setFeatureAccounts(e as Account[])}
            />
          </div>
        )}
      </FlexContainer>

      <FlexContainer justify="flex-end" className="mt-5">
        <AppButton
          label={t('button.cancel')}
          severity="secondary"
          type="outlined"
          onClick={onHide}
          className="mr-3"
          isDisabled={isSubmitting}
          data-testid="edit-feature-flag-cancel"
        />
        <AppButton
          label={t('button.save')}
          state={isSubmitting ? LoadingStatuses.LOADING : LoadingStatuses.IDLE}
          isDisabled={isSubmitting}
          onClick={handleEditFeatureFlag}
          data-testid="edit-feature-flag-submit"
        />
      </FlexContainer>
    </StyledDialog>
  );
};

import { handleAxiosError, like } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  ChangeEnrollDateFormValues,
  CourseEnrollmentTypeEnum,
  CourseEntityEnrollEnum,
  CourseEntityScheduleEnum,
  CourseScheduleType,
  CourseUser,
  EnrollFormValues,
  ForceCompleteFormValues,
  MaterialType,
  UserStatusEnum,
} from '@/client/courses';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects, User } from '@/client/users';
import { TableNamesEnum } from '@/common/constants';
import { userStatusOptions } from '@/common/constants/enroll-types';
import {
  notSystemRoleOptions,
  roleTranslations,
} from '@/common/constants/roles';
import { DialogContext } from '@/common/context';
import { ForceCompleteModal } from '@/components/courses/modals';
import { ChangeEnrollDateModal } from '@/components/courses/modals/ChangeEnrollDateModal';
import { EnrollModal } from '@/components/courses/modals/EnrollModal';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useChangeScheduleDate,
  useCourse,
  useCourseUsers,
  useDeleteEnrollment,
  useForceComplete,
  useUsersSchedules,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, usePendo } from '@/hooks/usePendo';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { calculateDayDifferenceFromToday } from '@/utils/helpers';
import { determineUserStatusColor } from '@/utils/helpers/ui.helper';
import {
  HubspotProperty,
  hubspotTrack,
  HubspotValue,
} from '@/utils/hubspot/hubspot.helper';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { FormEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Md5 } from 'ts-md5';
import { CoursesActionHeader } from './CoursesActionHeader';
import { CoursesTabs } from './CoursesTabs';

export const CourseUsersPage: React.FC<{ type: MaterialType }> = ({ type }) => {
  const { t } = useTranslation();
  const { id: courseId } = useParams();
  const { track } = useMixpanel();
  const toast = useToast();
  const { user } = useAppSelector(selectCurrentUserState);
  const account = useAppSelector(selectCurrentAccount);
  const { setDialogData } = useContext(DialogContext);
  const { pendoTrackEnrollment } = usePendo();
  const { can } = usePermission();
  const { canUseFeature } = useFeatureFlag();
  const isMaterial =
    type === MaterialType.CUSTOM_MATERIAL || type === MaterialType.RESOURCE;
  const { course, isLoading: isCourseLoading } = useCourse({
    courseId: courseId,
  });

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage, setSkip } =
    useTable();

  const [selectedUser, setSelectedUser] = useState<CourseUser | undefined>(
    undefined,
  );
  const [showChangeEnrollDateModal, setShowChangeEnrollDateModal] =
    useState(false);
  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const [showForceCompleteModal, setShowForceCompleteModal] = useState(false);

  const [multiSearchValue, setMultiSearchValue] = useState('');
  const debouncedSetMultiSearchValue = useMemo(
    () =>
      debounce((event: FormEvent) => {
        setSkip(0);
        setMultiSearchValue((event.target as HTMLInputElement).value);
      }, 300),
    [],
  );

  const {
    isLoading: isCourseUsersLoading,
    courseUsers,
    refetch,
  } = useCourseUsers(
    {
      take,
      skip,
      filters: [
        ...apiFilters,
        ...(multiSearchValue.length ? [like('name', multiSearchValue)] : []),
      ],
      sort: sort && sort.length > 0 ? [sort.join(',')] : [],
      enabled: true,
    },
    courseId,
    account?.id,
  );

  const handlePendoTrackEnrollment = (
    type: 'create' | 'update' | 'delete',
    user: CourseUser,
    date?: Date | null,
  ) => {
    if (course && account && user) {
      pendoTrackEnrollment({
        eventType: pendoEvent.enrollUser,
        type,
        courses: [course],
        accounts: [account],
        users: [user],
        date,
        oldEnrollDate: type === 'update' ? selectedUser?.schedule?.date : null,
      });
    }
  };

  const changeScheduleDate = useChangeScheduleDate();
  const handleChangeScheduleDate = async (data: ChangeEnrollDateFormValues) => {
    if (selectedUser?.schedule && data.date) {
      try {
        await changeScheduleDate.edit({
          scheduleId: selectedUser.schedule.id,
          scheduleType: CourseEntityScheduleEnum.USER,
          date: data.date,
          type: CourseScheduleType.STATIC,
        });

        if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
          // Track Mixpanel course enrollment with type update
          track('Update course enrollment', {
            Entity: 'User',
            'Course id': course?.id,
            'Course name': course?.name,
            'Old enroll date (UTC)': moment
              .utc(selectedUser?.schedule?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'New enroll date (UTC)': moment
              .utc(data?.date)
              .format(DateFormats.DATEONLY_FULL_MONTH),
            'Enrolled to': Md5.hashStr(selectedUser.id),
            'Account name': account?.name,
            'Total number of days': calculateDayDifferenceFromToday(data?.date),
            'Current date (UTC)': moment
              .utc()
              .format(DateFormats.DATEONLY_FULL_MONTH),
          });

          handlePendoTrackEnrollment('update', selectedUser, data.date);
        }

        await refetch();
        setSelectedUser(undefined);
        setShowChangeEnrollDateModal(false);
        toast?.success(
          t('toast.success'),
          t('courses.schedule.date.change.success'),
        );
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const usersSchedules = useUsersSchedules();
  const handleEnrollUser = async (data: EnrollFormValues) => {
    if (course && selectedUser) {
      try {
        await usersSchedules.add({
          courses: [course.id],
          users: [selectedUser.id],
          date: data.date,
          type: data.type,
        });

        if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
          handlePendoTrackEnrollment('create', selectedUser, data.date);
        }

        await refetch();
        toast?.success(t('toast.success'), t('courses.user.enroll.success'));

        if (user?.account?.freeTrialEndsAt) {
          hubspotTrack({
            [HubspotProperty.NEW_USER_ENROLLED_TO_A_COURSE]: HubspotValue.YES,
          });
        }

        setSelectedUser(undefined);
        setShowEnrollModal(false);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      }
    }
  };

  const deleteEnrollment = useDeleteEnrollment();
  const handleDeleteEnrollment = async (
    courseId: string,
    courseUser: CourseUser,
  ) => {
    try {
      await deleteEnrollment.post({
        courseId,
        request: { [CourseEntityEnrollEnum.USERS]: [courseUser.id] },
      });

      if (canUseFeature(Subjects.EVENT_TRACKING) && !isMaterial) {
        // Track Mixpanel course enrollment with type delete
        track('Delete course enrollment', {
          Entity: 'User',
          'Course name': course?.name,
          'Course id': course?.id,
          'Enroll date (UTC)': moment
            .utc(courseUser?.schedule?.date)
            .format(DateFormats.DATEONLY_FULL_MONTH),
          'Enrolled to': Md5.hashStr(courseUser?.id),
          'Account name': account?.name,
          'Current date (UTC)': moment
            .utc()
            .format(DateFormats.DATEONLY_FULL_MONTH),
        });

        handlePendoTrackEnrollment(
          'delete',
          courseUser,
          courseUser?.schedule?.date,
        );
      }

      await refetch();
      toast?.success(t('toast.success'), t('courses.user.unenroll.success'));

      setSelectedUser(undefined);
      setShowEnrollModal(false);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const completeCourse = useForceComplete();
  const handleForceComplete = async (data: ForceCompleteFormValues) => {
    if (!selectedUser?.id || !courseId) return;
    try {
      await completeCourse.complete({
        courseId,
        userId: selectedUser.id,
        score: data.score,
        accountId: account?.id as string,
      });
      refetch();
      setShowForceCompleteModal(false);
      toast?.success(
        t('toast.success'),
        t('course.forceComplete.success', { course: course?.name }),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (courseUser: CourseUser) => {
    const menu: MenuItem[] = [
      {
        label: t('generic.enroll'),
        icon: 'pi pi-calendar-plus',
        command: () => {
          setSelectedUser(courseUser);
          setShowEnrollModal(true);
        },
      },
    ];

    if (
      courseUser.schedule &&
      !courseUser.schedule.executedAt &&
      !courseUser.enrollment
    ) {
      menu.push({
        label: t('courses.change.enrollDate'),
        icon: 'pi pi-calendar',
        command: () => {
          setSelectedUser(courseUser);
          setShowChangeEnrollDateModal(true);
        },
      });
    }

    if (courseUser?.enrollment) {
      menu.push({
        label: t('generic.unenroll'),
        icon: 'pi pi-times',
        command: () => {
          setSelectedUser(courseUser);
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.deleteEnrollment'),
            message: t('dialog.deleteEnrollment.user', {
              name: `${courseUser?.firstName} ${courseUser?.lastName}`,
            }),
            onAccept: async () => {
              if (!courseUser?.enrollment || !courseId) return;
              setSelectedUser(courseUser);
              await handleDeleteEnrollment(courseId, courseUser);
            },
          });
        },
      });
    }

    if (
      courseUser?.status !== UserStatusEnum.COMPLETED &&
      courseUser?.status !== UserStatusEnum.SCHEDULED &&
      courseUser?.status !== UserStatusEnum.NOT_SCHEDULED &&
      can(Actions.UPDATE, Subjects.COURSE_FORCE_COMPLETE) &&
      !isMaterial
    ) {
      menu.push({
        label: t('course.forceComplete'),
        icon: 'pi pi-check',
        command: () => {
          setShowForceCompleteModal(true);
          setSelectedUser(courseUser);
        },
      });
    }

    return menu;
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'firstName',
      header: t('user'),
      sortable: true,
      filterable: false,
      style: {
        minWidth: '200px',
      },
      render: (row: CourseUser) => {
        return (
          <FlexContainer
            direction="column"
            className="group-cell"
            align="flex-start"
          >
            <span>
              {row.firstName} {row.lastName}
            </span>
            <span className="secondary-text">{row?.email}</span>
          </FlexContainer>
        );
      },
    },
    {
      field: 'role',
      header: t('generic.role'),
      sortable: false,
      filterable: true,
      filters: {
        type: FilterTypeEnum.SELECT,
        options: notSystemRoleOptions(t, user as User),
      },
      className: 'no-padding',
      render: (row: CourseUser) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            <div className="group-row">{roleTranslations(row?.role, t)}</div>
          </FlexContainer>
        );
      },
    },
    {
      field: 'enrollDate',
      header: t('generic.enrollDate'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byDate'),
        field: FilterNamesEnum.COURSE_ENTITY_UPCOMING_DATE,
        type: FilterTypeEnum.SELECT_UPCOMING_PAST,
        placeholder: t('filter.date'),
      },
      className: 'no-padding',
      render: (row: CourseUser) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            {row?.enrollDate ? (
              <div className="group-row">
                <FormatDate
                  format={DateFormats.TIMEDATE}
                  date={row?.enrollDate}
                />
              </div>
            ) : (
              <div className="group-row">&#8212;</div>
            )}
          </FlexContainer>
        );
      },
    },
    ...(account?.courseDueDays
      ? [
          {
            field: 'enrollment.dueDate',
            header: t('generic.dueDate'),
            sortable: true,
            filterable: true,
            filters: {
              label: t('filter.have.overdueCourses'),
              field: FilterNamesEnum.COURSE_ENTITY_USER_HAS_DUE_COURSES,
              type: FilterTypeEnum.SELECT_YES_NO,
              placeholder: t('generic.select'),
            },
            className: 'no-padding',
            render: (row: CourseUser) => {
              return (
                <FlexContainer direction="column" className="group-cell">
                  {row?.enrollment?.dueDate ? (
                    <div className="group-row">
                      <FormatDate
                        format={DateFormats.TIMEDATE}
                        date={row?.enrollment.dueDate}
                      />
                    </div>
                  ) : (
                    <div className="group-row">&#8212;</div>
                  )}
                </FlexContainer>
              );
            },
          },
        ]
      : []),
    {
      field: 'inheritedSchedule',
      header: t('courses.inheritedFrom'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      render: (row: CourseUser) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            <div className="group-row">
              {row?.inheritedSchedule?.source ===
              CourseEntityScheduleEnum.GROUP ? (
                <span>{t('group')}</span>
              ) : row?.inheritedSchedule?.source ===
                CourseEntityScheduleEnum.BRANCH ? (
                <span>{t('branch')}</span>
              ) : row?.inheritedSchedule?.source ===
                CourseEntityScheduleEnum.ACCOUNT ? (
                <span>{t('account')}</span>
              ) : (
                <div className="group-row">&#8212;</div>
              )}
            </div>
          </FlexContainer>
        );
      },
    },
    {
      field: 'status',
      header: t('generic.status'),
      sortable: true,
      filterable: true,
      filters: {
        label: t('filter.byStatus'),
        field: FilterNamesEnum.ENTITY_BY_STATUS,
        type: FilterTypeEnum.MULTI_SELECT,
        options: userStatusOptions(t),
      },
      className: 'no-padding',
      render: (row: CourseUser) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            <div className="group-row">
              {determineUserStatusColor(row?.status, t)}
            </div>
          </FlexContainer>
        );
      },
    },
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      className: 'no-padding',
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: CourseUser) => {
        return (
          <FlexContainer direction="column" className="group-cell">
            <div className="group-row justify-content-center">
              <DataTableActions
                menuItems={menuItems(row)}
                disabled={menuItems(row).length < 1}
              />
            </div>
          </FlexContainer>
        );
      },
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <DataTableFilters
        filters={getFiltersFromColumns(columns)}
        onFilter={onFilter}
        className="flex-initial"
        tableName={
          isMaterial
            ? TableNamesEnum.MATERIAL_USERS
            : TableNamesEnum.COURSE_USERS
        }
      />
      <div className="p-input-icon-left flex-auto min-w-300">
        <InputText
          className="w-full"
          onInput={debouncedSetMultiSearchValue}
          placeholder={t('courses.users.search')}
          autoComplete="off"
        />
        <i className="pi pi-search" />
      </div>
      <DataTableColumnsMultiselect
        columns={columns}
        tableName={
          isMaterial
            ? TableNamesEnum.MATERIAL_USERS
            : TableNamesEnum.COURSE_USERS
        }
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        alwaysVisibleColumns={alwaysVisibleColumns}
      />
    </DataTableToolbar>
  );

  return (
    <>
      {isCourseLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>
            {isMaterial
              ? t('material.users.loading')
              : t('course.users.loading')}
          </h3>
        </FlexContainer>
      )}
      {!isCourseLoading && course && (
        <>
          <CoursesActionHeader course={course} onTriggerRefetch={refetch} />
          {courseId && <CoursesTabs courseId={courseId} type={course.type} />}
          <DataTable
            dataKey="dataId"
            data={courseUsers?.result.map((x) => ({
              ...x,
              dataId: `${x.id}-${x?.schedule?.id}-${x?.inheritedSchedule?.date}`,
            }))}
            count={courseUsers?.count as number}
            isLoading={isCourseUsersLoading}
            toolbar={toolbar}
            columns={columns}
            visibleColumns={visibleColumns}
            onPage={onPage}
            rows={take}
            skip={skip}
            onSort={onSort}
            sort={sort}
            classNames="multiline-datatable with-actions"
          />

          <EnrollModal
            type={CourseEnrollmentTypeEnum.ENROLL}
            account={account as Account}
            user={selectedUser as unknown as User}
            enrollType={CourseEntityScheduleEnum.USER}
            visible={showEnrollModal && !!selectedUser}
            onSubmit={handleEnrollUser}
            onHide={() => setShowEnrollModal(false)}
            course={course}
          />

          <ChangeEnrollDateModal
            date={selectedUser?.schedule?.date ?? undefined}
            visible={showChangeEnrollDateModal && !!selectedUser}
            onSubmit={handleChangeScheduleDate}
            onHide={() => setSelectedUser(undefined)}
          />

          <ForceCompleteModal
            visible={showForceCompleteModal}
            onSubmit={handleForceComplete}
            onHide={() => setShowForceCompleteModal(false)}
            userName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
            courseName={course.name}
          />
        </>
      )}
    </>
  );
};

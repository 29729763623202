import { FiltersType } from '@/api/types';
import { UsersReportType } from '@/client/reports';
import { Subjects } from '@/client/users';
import { SummaryReportDatatable } from '@/components/reports/datatables';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useMixpanel } from '@/hooks/useMixpanel';
import { pendoEvent, pendoProperty, usePendo } from '@/hooks/usePendo';
import { selectCurrentAccount } from '@/store/features/account';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { ReportsActionHeader } from './ReportsActionHeader';

export const SummaryReportsPage: React.FC = () => {
  const [exportFilters, setExportFilters] = useState<FiltersType | undefined>();
  const [exportSorting, setExportSorting] = useState<
    [string, 'asc' | 'desc'] | null
  >();
  const [forceRefetch, setForceRefetch] = useState<boolean>(false);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { pendoTrack } = usePendo();
  const { track } = useMixpanel();
  const { canUseFeature } = useFeatureFlag();

  useEffect(() => {
    if (canUseFeature(Subjects.EVENT_TRACKING) && (mixpanel as any)?.__loaded) {
      pendoTrack(pendoEvent.summaryReportVisited, {
        [pendoProperty.forAccountId]: currentAccount?.id ?? '',
        [pendoProperty.forAccountName]: currentAccount?.name ?? '',
      });
      track('View Summary reports');
    }
  }, [(mixpanel as any)?.__loaded]);

  return (
    <>
      <ReportsActionHeader
        type={UsersReportType.SUMMARY}
        filters={exportFilters}
        sort={exportSorting}
        setForceRefetch={setForceRefetch}
      />
      <SummaryReportDatatable
        setExportFilters={setExportFilters}
        setExportSorting={setExportSorting}
        forceRefetch={forceRefetch}
      />
    </>
  );
};
